import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import style from './index.module.scss'
import { Country, JobClasses, getIDFromKeyword } from '../../service'
import Pagination from '@mui/material/Pagination'
import { useCompanyDetail } from '../../DataProvider'
import { useParams } from 'next/navigation'
import Image from 'next/image'
import Loading from 'app/components/loading'
import className from 'classnames'
import { useFirstRender } from 'helpers/useFirstRender'
import useMediaQuery from '@mui/material/useMediaQuery'
import { languageContext } from 'app/components/providers/languageProvider'
import Empty from 'app/components/empty/empty'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { debounce } from 'lodash-es'
import MaterialButton from 'components/MaterialButton'
import searchUrl from './search.svg'
import { JobsSearchCard } from '../SearchPanel'
import { fetchCompanyRemoteJobs } from '../../../service'
import { convertClientToBackendLang } from 'helpers/country.base'
interface Props {
  functions: JobClasses[]
  locationRemote?: any
  languageRemote?: any
  remoteJobs?: any
}

const parseData = (remoteJobs) => {
  const { company_hot_job, job_nums, page } = remoteJobs?.company_remote_jobs || {}

  return {
    jobs: company_hot_job,
    totalJobs: job_nums,
    page
  }
}

const SearchPanelRemote = (props: Props) => {
  const params = useParams()
  const { remoteJobs, isRemoteWorldwide, lang } = useCompanyDetail()
  const { company_hot_job = {}, job_nums } = remoteJobs?.company_remote_jobs || {}
  const { search_info = {} } = remoteJobs

  const [jobsData, setJobsData] = useState(company_hot_job)
  const [page, setPage] = useState({ page: 1, totalJobs: job_nums })

  const inputText = useRef('')
  const [loading, setLoading] = useState(false)
  const pageRef = useRef(1)
  const [isMobileLoading, setIsMobileLoading] = useState(false)
  const id = getIDFromKeyword(params.keyword as any)

  const firstRef = useRef<HTMLDivElement | null>(null)
  const isMobile = useMediaQuery('(max-width:768px)')
  const contextLang = useContext(languageContext)
  const { overview } = contextLang.companyDetail
  const firstRender = useFirstRender()
  const [searchParams, setSearchParams] = useState({
    language_id: '',
    job_function_id: '',
    remote_country_id: ''
    // is_remote_worldwide: 1
  })

  const searchFunc = (jobTitle?: string, page = 1, params: any = {}) => {
    if (isMobile) {
      setIsMobileLoading(true)
    } else {
      setLoading(true)
    }

    const reqData = {
      company_ids: id,
      size: 10,
      page,
      query: jobTitle || inputText.current,
      ...params
    }

    if (isRemoteWorldwide && params.remote_country_ids === '-1') {
      reqData.is_remote_worldwide = 1
      delete reqData.remote_country_ids
    }

    fetchCompanyRemoteJobs(id, convertClientToBackendLang(lang), {
      size: 10,
      page,
      query: jobTitle || inputText.current,
      ...params
    })
      .then((res) => {
        const { jobs, totalJobs, page } = parseData(res)
        setPage({ page, totalJobs })
        if (isMobile && reqData.page > 1) {
          setJobsData((pre) => {
            return [...pre, ...jobs]
          })
          return
        }
        setJobsData(jobs)
      })
      .catch(() => {
        setJobsData([])
      })
      .finally(() => {
        setLoading(false)
        setIsMobileLoading(false)
      })
  }

  const debouncedFunction = useCallback(
    debounce((params) => {
      searchFunc(null, 1, params)
    }, 300),
    []
  )
  useEffect(() => {
    if (firstRender) return
    debouncedFunction(searchParams)
  }, [searchParams])

  return (
    <div className={style.search_container}>
      <div className={style.search_input_wrapper}>
        <div className={style.search_input_layout}>
          <label htmlFor='input-search' className={style.job_search_container}>
            <Image width={16} height={16} className={style.job_prefix} src={searchUrl} alt='_' />
            <input
              id={'input-search'}
              name={'input-search'}
              placeholder={
                // overview.SearchPlaceholder
                search_info?.input_text
              }
              className={style.job_search_input}
              onChange={(e) => {
                inputText.current = e.target.value
                // searchFunc(e.target.value,location)
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  if (loading) return
                  searchFunc(null, 1, searchParams)
                }
              }}
            />
          </label>
        </div>
        <div
          className={className({
            [style.search_button]: true
            //  [style.button_loading]: loading
          })}
          onClick={() => {
            if (loading) return
            pageRef.current = 1
            searchFunc(null, 1, searchParams)
          }}
        >
          <span>
            {search_info?.search_text || overview.Find}
            {/* {isMobile ? overview.Find : overview['FindNow']} */}
          </span>
        </div>
      </div>
      <div className={style.filter_container}>
        {props.functions?.length > 0 && (
          <div className={style.filter_layout}>
            <Tabs
              key={'job_function_ids'}
              value={searchParams.job_function_id}
              onChange={(event, newValue) =>
                setSearchParams({
                  ...searchParams,
                  job_function_id: newValue
                })
              }
              variant='scrollable'
              scrollButtons={true}
              aria-label='scrollable prevent tabs example'
            >
              <Tab label={overview.All} value={''} key={'-999'} />
              {props.functions
                .filter((v) => Boolean(v.id))
                ?.map((item, index) => (
                  <Tab label={item.value} value={item.id} key={item.id} />
                ))}
            </Tabs>
          </div>
        )}
        {props.locationRemote?.length > 0 && (
          <div className={style.filter_layout}>
            <Tabs
              key={'remote_country_ids'}
              value={searchParams.remote_country_id}
              onChange={(event, newValue) =>
                setSearchParams({
                  ...searchParams,
                  remote_country_id: newValue
                })
              }
              variant='scrollable'
              scrollButtons={true}
              aria-label='scrollable prevent tabs example'
            >
              <Tab label={overview.All} value={''} key={'-999'} />
              {isRemoteWorldwide && <Tab label={overview.worldwide} value={'-1'} key={'-1'} />}
              {props.locationRemote
                .filter((v) => Boolean(v.id))
                ?.map((item, index) => (
                  <Tab label={item.value} value={item.id} key={item.id} />
                ))}
            </Tabs>
          </div>
        )}
        {props.languageRemote?.length > 0 && (
          <div className={style.filter_layout}>
            <Tabs
              key={'language_ids'}
              value={searchParams.language_id}
              onChange={(event, newValue) =>
                setSearchParams({
                  ...searchParams,
                  language_id: newValue
                })
              }
              variant='scrollable'
              scrollButtons={true}
              aria-label='scrollable prevent tabs example'
            >
              <Tab label={overview.All} value={''} key={'-999'} />
              {props.languageRemote
                .filter((v) => Boolean(v.id))
                ?.map((item, index) => (
                  <Tab label={item.value} value={item.id} key={item.id} />
                ))}
            </Tabs>
          </div>
        )}
      </div>
      <div className={style.search_content_wrapper}>
        <div className={style.filter_split} ref={firstRef}></div>
        <div className={style.content_layout}>
          {loading ? (
            loading && (
              <div className={style.loading_wrapper}>
                <Loading />
              </div>
            )
          ) : !!jobsData?.length ? (
            !loading &&
            jobsData.map((item, index) => {
              return (
                <JobsSearchCard
                  isOnLine={true}
                  item={item}
                  {...item}
                  key={item.job_title + item.id + index}
                />
              )
            })
          ) : (
            <div className={style.noData}>
              <Empty lang={contextLang.search} description={null} />
            </div>
          )}
        </div>
        <div className={style.pagination}>
          {isMobile
            ? Math.ceil(job_nums / 10) > pageRef.current && (
                <MaterialButton
                  variant='contained'
                  sx={{
                    height: '44px',
                    borderRadius: '4px',
                    width: '144px',
                    textTransform: 'capitalize !important',
                    background: '#ffffff',
                    boxShadow: 'none',
                    border: '1px solid #2378E5',
                    color: '#2378E5',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    ':hover': {
                      backgroundColor: '#fff',
                      boxShadow: 'none'
                    },
                    '-loading': {
                      border: 'none'
                    }
                  }}
                  isLoading={isMobileLoading}
                  onClick={() => {
                    pageRef.current++
                    searchFunc('', pageRef.current, searchParams)
                  }}
                >
                  {contextLang?.home?.seeMoreBtn}
                </MaterialButton>
              )
            : Boolean(page.totalJobs && !loading) && (
                <Pagination
                  page={page.page}
                  count={Math.ceil(page.totalJobs / 10)}
                  onChange={(e, v) => {
                    if (isMobile) {
                      firstRef.current &&
                        firstRef.current?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                          inline: 'nearest'
                        })
                    }
                    searchFunc(null, v, searchParams)
                    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
                  }}
                  shape='rounded'
                  color={'primary'}
                />
              )}
        </div>
      </div>
    </div>
  )
}

export default SearchPanelRemote
